<template>
  <el-container>
    <el-header>
      <h1>Chat Application</h1>
    </el-header>
    <el-main>
      <el-scrollbar class="chat-container">
        <div class="chat-box" v-for="(message, index) in messages" :key="index">
          <el-card :class="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}">
            {{ message.content }}
          </el-card>
        </div>
      </el-scrollbar>
    </el-main>
    <el-footer>
      <el-input
        v-model="userInput"
        placeholder="Type your message..."
        @keyup.enter="sendMessage"
        clearable
      ></el-input>
      <el-button type="primary" @click="sendMessage">Send</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChatApp',
  data() {
    return {
      userInput: '',
      messages: [
        { role: 'assistant', content: 'Hello! How can I help you today?' }
      ]
    };
  },
  methods: {
    async sendMessage() {
      if (this.userInput.trim() === '') return;
      this.messages.push({ role: 'user', content: this.userInput });
      const userMessage = this.userInput;
      this.userInput = '';
      try {
        const response = await axios.post('https://www.kfcvme50.icu/chat', { message: userMessage });
        this.messages.push({ role: 'assistant', content: response.data.reply });
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  }
};
</script>

<style>
.chat-container {
  height: 70vh;
  padding: 10px;
  overflow-y: auto;
}

.chat-box {
  margin-bottom: 10px;
}

.user-message {
  text-align: right;
  background-color: #e1ffc7;
  padding: 10px;
  border-radius: 10px;
  color: #0000ff; /* 用户消息文本颜色 */
}

.assistant-message {
  text-align: left;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  color: #ff0000; /* 助手消息文本颜色 */
}
</style>
